import './Media.css';
import ReactPlayer from 'react-player'
import data from "../content/Media.json"
import { Helmet } from 'react-helmet-async';

interface MediaProps {
    url: string;
}

function MediaItem(props: MediaProps): JSX.Element{ 
    return (
        <div className='Media-item'>
            <ReactPlayer 
                url={props.url} 
                width={"300px"}
                height={"300px"}
            />
        </div>
    )
}


export function Media(): JSX.Element{

    return (
        <div className="Media-Background">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Charles Emogor | Media</title>
                <meta name="description" content='Conservationist and tropical ecologist'></meta>
                <link rel="canonical" href="/media" />
            </Helmet>
            <div className="Media-Container">
                <p className="Description-Media">{data.description}</p>
                <p>{data.comment}</p>
                <div className="Media-Container-Items">
                    {data.media.map((media, index) => {
                        return (
                            <MediaItem url={media.url}/>
                        )
                    })}
                </div>
            </div>
        </div>
    );
}
    