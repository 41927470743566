import './Mentoring.css';
import data from "../content/Mentoring.json";
import { Helmet } from 'react-helmet-async';

export function Mentoring(): JSX.Element {

    return (
        <div className="Mentoring-Background">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Charles Emogor | Mentoring</title>
                <meta name="description" content="Support for young professionals, scholarships, and fellowships"></meta>
                <link rel="canonical" href="/mentoring" />
            </Helmet>

            <iframe
                className="Google-Form-Container"
                src={data.googleFormUrl}
                width="100%"
                height="100%"
                frameBorder="0"
                marginHeight={0}
                marginWidth={0}
                title="Mentoring Form"
            >
                Loading…
            </iframe>
        </div>
    );
}
