import './ProjectDetails.css';
import { useParams } from 'react-router-dom';
import data from '../content/Projects.json';
import { Helmet } from 'react-helmet-async';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';


export function ProjectDetails(): JSX.Element {
    const { projectId } = useParams<{ projectId: string }>();

    // Find the project using the projectId from the route
    const project = data.projects.find((proj) => proj.id === projectId);

    if (!project) {
        return (
            <div className="ProjectDetails-Container">
                <h1 className="ErrorTitle">Project Not Found</h1>
                <p className="ErrorMessage">
                    We couldn't find the project you're looking for. Please check the URL or go back to the <a href="/projects">Projects Page</a>.
                </p>
            </div>
        );
    }

    return (
        <div className="ProjectDetails-Container">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`Charles Emogor | ${project.title}`}</title>
                <meta name="description" content={`Learn more about the project titled ${project.title}.`} />
                <link rel="canonical" href={`/projects/${projectId}`} />
            </Helmet>

            <h1 className="ProjectDetails-Title">{project.title}</h1>

            {project.status && (
                <div>
                    <p><strong>Status:</strong> {project.status}</p>
                </div>
            )}

            {project.duration && (
                <div>
                    <p><strong>Duration:</strong> {project.duration}</p>
                </div>
            )}

            {project.description && (
                <div className="ProjectDetails-Description">
                    <h3>Description:</h3>
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                        {project.description}
                    </ReactMarkdown>
                </div>
            )}

            {project.funder && (
                <div className="ProjectDetails-Funder">
                    <strong>Funder:</strong> {project.funder}
                </div>
            )}

            {project["external-project-link"] && (
                <div className="ProjectDetails-ExternalLink">
                    <strong>External project link:</strong> <a href={project["external-project-link"]} target="_blank" rel="noopener noreferrer">Visit Project Website</a>
                </div>
            )}

            {project.media && project.media.length > 0 && (
                <div className="ProjectDetails-Media">
                    <strong>Media: </strong>
                    {project.media.map((mediaItem, index) => 
                        project.media_links && project.media_links[index] !== "NO_LINK" ? (
                            <a 
                                key={index} 
                                href={project.media_links[index]} 
                                target="_blank" 
                                rel="noopener noreferrer"
                            >
                                {mediaItem}
                            </a>
                        ) : (
                            <span key={index}>{mediaItem}</span>
                        )
                    ).reduce((prev, curr) => <>{prev}, {curr}</>)}
                </div>
            )}

            {project.publications && project.publications.length > 0 && (
                <div className="ProjectDetails-Publications">
                    <strong>Publications:</strong>
                    <ul>
                        {project.publications.map((publication, index) => (
                            <li key={index}>{publication}</li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}
