import data from "../content/HeaderFooter.json"
import { useLocation, Link } from "react-router-dom";
import './HeaderFooter.css';
import { useCallback, useEffect, useState } from "react";
export function Header(): JSX.Element {

    const location = useLocation();
    const [path, setPath] = useState(location.pathname);
    const [name, setName] = useState("home");
    
    const setCorrectBurgerColor = useCallback(() => {
        const root = document.documentElement;
        if (location.pathname === "/" || location.pathname === "/mentoring") {
          root.style.setProperty('--burger', 'var(--light)');
        } else {
          root.style.setProperty('--burger', 'var(--dark)');
        }
      }, [location.pathname]);

    const findName = useCallback(() => {
        data.header.items.forEach(item => {
            if (item.link === path) {
                setName(item.title)
            }
        })
    }, [path, setName]);

    const setWidths = useCallback(() => {
        let dictWithGaps = new Map();
        let dictWithoutGaps = new Map();
        let sum = 0;
        //to list
        let list = Array.from(data.header.items);
        let reversedList = list.reverse()

        for (let i = 0; i < list.length; i++) {
            const width = getComputedStyle(document.getElementById(list[i].title)?? document.documentElement).getPropertyValue('width');
            let gap = 0;
            if (i !== 0) {
                gap = parseInt(getComputedStyle(document.getElementById("Header-ContainerID")?? document.documentElement).getPropertyValue('gap'));
            }
            dictWithGaps.set(list[i].title, parseInt(width) + gap);
            dictWithoutGaps.set(list[i].title, parseInt(width));
            sum += parseInt(width) + gap;
        }
        let move = sum;
        for (let i = 0; i < reversedList.length ; i++) {

            move -= (dictWithGaps.get(reversedList[i].title));
            
            if (reversedList[i].title === name){
                break;
            }
        }
        document.documentElement.style.setProperty("--moving_left_spacing", move + "px");
        document.documentElement.style.setProperty("--moving_width", dictWithoutGaps.get(name) + "px");
    }, [name]);


    useEffect (() => {
        setPath(location.pathname);
        findName();
        setWidths();
        setCorrectBurgerColor()
    }, [location.pathname, name, findName, setWidths, setCorrectBurgerColor]);

    let burger = <svg viewBox="0 0 36 36" width="36" height="36">
                    <rect x="4.5" y="4.5" width="27" height="3" rx="1.5" fill="var(--burger)"/>
                    <rect x="4.5" y="13.5" width="27" height="3" rx="1.5" fill="var(--burger)"/>
                    <rect x="4.5" y="22.5" width="27" height="3" rx="1.5" fill="var(--burger)" />
                </svg>

    let burgerStable = <svg viewBox="0 0 36 36" width="36" height="36">
                <rect x="4.5" y="4.5" width="27" height="3" rx="1.5" fill="var(--burger-stable)"/>
                <rect x="4.5" y="13.5" width="27" height="3" rx="1.5" fill="var(--burger-stable)"/>
                <rect x="4.5" y="22.5" width="27" height="3" rx="1.5" fill="var(--burger-stable)" />
            </svg>
  
    
    const [openPanel, setOpenPanel] = useState(false);

    function open(){
        setOpenPanel(true);
    }

    function close(){
        setOpenPanel(false);
    }


    return (
        <div className="Header-Container-Wrapper">
            <button className="BurgerButton ShowIfMobile" onClick={open}>{burger}</button>
            <div className={openPanel ? "HeaderMobilePanel panelOpen" : "HeaderMobilePanel"}>
                <button className="BurgerButton" onClick={close}>{burgerStable}</button>
                <div className="HeaderMobilePanel-Container">
                {data.header.items.map((item, index) => {
                    return (
                        <Link id={item.title} key={index + " header button"} to={item.link} onClick={() => {setName(item.title); close()}}>
                            <button className={(path === item.link) ? "active" : ""}>{item.title}</button>
                        </Link>
                    )
                })}   
                </div>
            </div>
            <div id="Header-ContainerID" className={"Header-Container ShowIfDesktop"}>
                {data.header.items.map((item, index) => {
                    return (
                        <Link id={item.title} key={index + " header button"} to={item.link} onClick={() => setName(item.title)}>
                            <button className={(path === item.link) ? "active" : ""}>{item.title}</button>
                        </Link>
                    )
                })}     
                <span className={"Header-MovingBar noselect"}>  </span>
            </div>
        </div>
    );
}

export function Footer(): JSX.Element {

    const location = useLocation();
    const [hide, setHide] = useState(false);

    useEffect(() => {
        if (location.pathname === "/" || location.pathname === "") {
            setHide(true);
        } else {
            setHide(false);
        }
    }, [location])

    return (
        <footer>
            {/* <div className="Footer-Container">
                <a className="ContactMe" href={"mailto:" + data.footer.contact.email}>
                    <button>{data.footer.contact.title}</button>
                </a>  
                <a className="CoverLetter" href={data.footer.cover.link} download>
                    <button>{data.footer.cover.title} </button>
                </a>
            </div> */}
            <div className={hide ? "Footer-BottomContainer  hide" : "Footer-BottomContainer show"}>
                <p className="Footer-BottomContainer-P"><strong>{data.footer.bottom_text}</strong></p>
                <a className="Footer-BottomContainer-P" href={data.footer.cv.link} download>
                    <p className="Footer-BottomContainer-P">{data.footer.cv.title} </p>
                </a>
                <a className="Footer-BottomContainer-P" href={"mailto:" + data.footer.contact.email}>
                    <p className="Footer-BottomContainer-P">{data.footer.contact.email}</p>
                </a>  
            </div>
        </footer>
    );
}

  