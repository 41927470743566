import './Projects.css';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import data from '../content/Projects.json';

export function Projects(): JSX.Element {
    const navigate = useNavigate();

    const handleProjectClick = (projectId: string) => {
        navigate(`/projects/${projectId}`);
    };

    return (
        <div className="ProjectsPage-Container">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Charles Emogor | Projects</title>
                <meta name="description" content="Conservationist and tropical ecologist researching the dynamics of biological resource use in the tropics." />
                <link rel="canonical" href="/projects" />
            </Helmet>
            
            <div className="ProjectsGrid">
                {data.projects.map((project, index) => (
                    <div
                        className={`ProjectCard ProjectCard-${index % 5}`}
                        key={project.id}
                        onClick={() => handleProjectClick(project.id)}
                        style={{
                            backgroundImage: `url(${project.image})`, // Set background image from JSON
                            backgroundSize: 'cover', // Ensure the image covers the card
                            backgroundPosition: 'center' // Center the image
                        }}
                    >
                        <h2 className="ProjectTitle">{project.title}</h2>
                        { project.photoCredit != '' &&
                            <p className="PhotoCredit">{project.photoCredit}</p> 
                        }
                    </div>
                ))}
            </div>
        </div>
    );
}
