import { Routes, Route} from 'react-router-dom';
import { Header, Footer } from './components/HeaderFooter';
import { NewMain } from './pages/NewMain';
import { Projects } from './pages/Projects';
import { About } from './pages/About'
import { Publications } from './pages/Publications';
import { Media } from './pages/Media';
import { Mentoring } from './pages/Mentoring';
import {Helmet} from "react-helmet-async";
import {ProjectDetails} from './pages/ProjectDetails'
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';


function App() {

  const location = useLocation();

  useEffect(() => {
    // Define background colors for each route
    const backgroundColors: { [key: string]: string } = {
      '/projects': 'white', 
    };

    const headerColors: { [key: string]: string } = {
      '/': 'var(--light)'
    }

    const color = backgroundColors[location.pathname as string] || 'var(--light)';
    const headerColor = headerColors[location.pathname as string] || 'var(--primary)';

    document.body.style.backgroundColor = color;
    document.documentElement.style.setProperty("--header-color", headerColor);

    return () => {
      document.body.style.backgroundColor = '';
    };
  }, [location.pathname]); // Re-run effect on path change

  return (
    <>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Charles Emogor</title>
          <meta name="description" content='Conservationist and tropical ecologist'></meta>
          <link rel="canonical" href="/" />
      </Helmet>
      <Header/>
        <Routes>
          <Route 
              path="/" 
              element={<NewMain/>}
          />
          <Route 
              path = '/about'
              element = {<About/>}
          />
          <Route 
              path="/projects" 
              element={<Projects/>}
          />   
          <Route path="/projects/:projectId" element={<ProjectDetails />} />

          <Route 
              path="/mentoring" 
              element={<Mentoring/>}
          />
          <Route 
              path="/publication" 
              element={<Publications/>}
          />
           <Route 
              path="/media" 
              element={<Media/>}
          />
        </Routes>
      <Footer/>
    </>
  );
}

export default App;
