import './About.css';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import data2 from "../content/Main.json";

export function About(): JSX.Element {
    const navigate = useNavigate();

    const handleProjectClick = (projectId: string) => {
        navigate(`/projects/${projectId}`);
    };

    return (
        <div className="AboutPage-Container">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Charles Emogor | About</title>
                <meta name="description" content="Conservationist and tropical ecologist researching the dynamics of biological resource use in the tropics." />
                <link rel="canonical" href="/projects" />
            </Helmet>
            
            <div className="IntroductionSection IntroContent">
                <h2 className='AboutH2'>About Me</h2>
                <div className="CharlesImage">
                    <img className="CharlesImage" src={data2.image.src} alt={data2.image.alt} />
                    <p className="PhotoCredit">{data2.image.credit}</p> 
                </div>
                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                    {data2.introduction.content}
                </ReactMarkdown>
            </div>

            {/* News Section */}
            <h2 className='AboutH2'>News</h2>
            <ul className='News'>
                {data2.news.map((item, index) => (
                    <li key={index}>
                        <strong>{item.date}:</strong> 
                        <ReactMarkdown remarkPlugins={[remarkGfm]}>
                            {item.content}
                        </ReactMarkdown>
                    </li>
                ))}
            </ul>
        </div>
    );
}
